<template>
  <b-row>
    <b-col cols="12" class="mb-32">
      <b-row align-h="between" class="mt-n32">
        <b-col class="hp-flex-none w-auto mt-32"> </b-col>
        <b-col class="hp-flex-none w-auto mt-24">
          <b-row>
            <b-col class="hp-flex-none w-auto pr-0">
              <b-button
                v-show="
                  currentUser &&
                  checkIfHaveRole(currentUser.rol, [
                    ROLES.coordinator,
                    ROLES.superadmin,
                  ])
                "
                @click="$refs.editPlaceUser.show(null)"
                variant="primary"
                class="btn-ghost"
                >Agregar Miembro</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12" class="mb-32">
      <h2 class="mb-0">Miembros</h2>
      <h5 class="mb-0 text-black-80 hp-text-color-dark-30">
        Listado de todos los miembros
      </h5>
    </b-col>

    <b-col cols="12" class="mb-32">
      <b-row>
        <b-col cols="12" xl="12" class="col-xxxl-12">
          <b-input-group size="lg">
            <b-form-input
              type="text"
              placeholder="Buscar Miembros"
            ></b-form-input>

            <template #append>
              <b-button
                variant="outline-info-3"
                class="px-16 px-sm-64 hp-hover-text-color-black-0"
                type="button"
              >
                Búsqueda
              </b-button>
            </template>
          </b-input-group>
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12">
      <b-row v-if="wrappedPlaceUsers.length">
        <b-col
          cols="12"
          md="6"
          xl="6"
          :key="index"
          v-for="(item, index) in wrappedPlaceUsers"
          class="mb-32"
        >
          <card-basic
            :title="item.full_name"
            :desc="item.rol.name"
            :img="item.image"
          >
            <template #actions>
              <div>
                <b-button
                  v-show="
                    currentUser &&
                    checkIfHaveRole(currentUser.rol, [
                      ROLES.coordinator,
                      ROLES.superadmin,
                    ])
                  "
                  size="sm"
                  v-b-tooltip.hover
                  title="Actualizar miembro"
                  variant="warning"
                  class="mt-16 mx-3 btn-ghost"
                  @click="$refs.editPlaceUser.show(item)"
                >
                  <i class="iconly-Curved-Edit"></i>
                </b-button>
                <b-button
                  v-show="
                    currentUser &&
                    checkIfHaveRole(currentUser.rol, [
                      ROLES.coordinator,
                      ROLES.superadmin,
                    ])
                  "
                  size="sm"
                  v-b-tooltip.hover
                  title="Eliminar miembro"
                  variant="danger"
                  @click="deletePlaceUser(item)"
                  class="mt-16 btn-ghost"
                >
                  <i class="iconly-Curved-Delete"></i>
                </b-button>
              </div>
            </template>
          </card-basic>
        </b-col>
      </b-row>
      <h5 v-else class="text-center mt-16">Sin miembros actualmente</h5>
    </b-col>
    <update-or-create-place-user
      @success="
        getPlaceUsers({
          place_id: $route.params.id,
        })
      "
      ref="editPlaceUser"
    ></update-or-create-place-user>
  </b-row>
</template>

<script>
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import CardBasic from "@/view/components/common/cards/CardBasic.vue";
import { mapActions, mapGetters } from "vuex";
import UpdateOrCreatePlaceUser from "./updateOrCreatePlaceUser.vue";
import { verifyRole } from "@/mixins/role";

export default {
  mixins: [verifyRole],
  components: {
    PageTitle,
    CardBasic,
    UpdateOrCreatePlaceUser,
  },
  mounted() {
    this.getPlaceUsers({
      place_id: this.$route.params.id,
    });
    this.getSections();
  },
  methods: {
    ...mapActions({
      getPlaceUsers: "place/getPlaceUsers",
      sendDeletePlaceUser: "place/deletePlaceUser",
      getSections: "project/getSections",
    }),
    deletePlaceUser(item) {
      this.$bvModal
        .msgBoxConfirm("Esta acción no se puede revertir", {
          title: "¿Estas seguro de eliminar este miembro?.",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-info-3",

          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.sendDeletePlaceUser(item.id);
            if (resp.status == 204) {
              this.$bvToast.toast(`Miembro eliminado correctamente`, {
                title: `Excelente`,
                variant: "primary",
                solid: false,
              });
            }
            this.getPlaceUsers({
              place_id: this.$route.params.id,
            });
          }
        })
        .catch((err) => {
          return err;
        });
    },
  },
  computed: {
    ...mapGetters({
      placeUsers: "place/placeUsers",
      currentUser: "auth/currentUser",
    }),
    wrappedPlaceUsers() {
      return this.placeUsers.map((place) => {
        return {
          ...place,
          full_name: place.customuser.first_name
            ? `${place.customuser.first_name} ${place.customuser.last_name}`
            : "Super Administrador",
          image: place.image
            ? place.image
            : require("@/assets/img/app/user/user-icon.png"),
        };
      });
    },
  },
};
</script>
